

import React, { useEffect, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

const Property = () => {

  const scrollContainerRef = useRef();
  const pricingPlans = [
        {
          title: "SILVER",
          price: "₹ 299",
          features: [
            "Customer Package UNLIMITED Property Leads",
          ],
          Ads: "Feature Ads",
          text:'Make your Property features ans sell faster',
    
          pack: "60 Days / 1 Property",
          duration:'30 Days'
    
        },
        {
          title: "SILVER PLUS",
          price: "₹ 499",
          features: [
            "Customer Package UNLIMITED Property Leads",
          ],
          Ads: "Feature Ads",
          text:'Make your Property features ans sell faster',
    
          pack: "120 Days / 3 Property",
          duration:'30 Days'
    
        },
        {
          title: "GOLD MINI",
          price: "₹ 999",
          features: [
            "Customer Package UNLIMITED Property Leads",
          ],
          Ads: "Feature Ads",
    text:'Make your Property features ans sell faster',
          pack: "120 Days / 5 Property",
    duration:'30 Days'
        },
      ];
    

  useEffect(() => {
    const handleWheel = (e) => {
      // Only handle horizontal scroll if deltaX is greater than deltaY
      if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
        e.preventDefault(); // Prevent default behavior for horizontal scroll
        scrollContainerRef.current.scrollLeft += e.deltaY; // Scroll horizontally
      }
    };

    const scrollContainer = scrollContainerRef.current;

    // Attach the wheel event listener to the container
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel);
    }

    // Cleanup event listener on unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <div className="container-fluid text-center mt-5 d-flex flex-column justify-content-center align-items-center">
      <div className="position-relative text-center w-100">
        <img 
          src="https://img.freepik.com/premium-vector/cartoon-living-room-house-inside-interior-house-with-furniture-stairs-teenage-luxury-room_730604-146.jpg?w=360" 
          alt="Sample" 
          className="img-fluid w-100" 
        />
        <div className="position-absolute top-50 start-50 translate-middle">
          <h1 className="text-white mb-3" style={{ fontSize: '2rem', whiteSpace: 'nowrap' }}>
            Upgrade Membership
          </h1>
          <p className="text-white" style={{ fontSize: '1.2rem', maxWidth: '400px', margin: '0 auto' }}>
            Start being a celebrity with our premium subscription plans
          </p>
        </div>
      </div>
      <div
        className="row justify-content-start mx-auto"
        ref={scrollContainerRef}
        style={{
          width: "450px",
          overflowX: "auto", // Enable horizontal scrolling
          overflowY: "hidden", // Disable vertical scrolling
          display: "flex",
          flexWrap: "nowrap",
          scrollBehavior: "smooth",
          gap: "20px", // Gap between cards
          scrollbarWidth: "none", // Hides scrollbar for Firefox
          WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
          paddingLeft: "10px", // Adjust padding to ensure visibility
        }}
      >
        {pricingPlans.map((plan, index) => (
          <div
            className="card shadow-sm"
            style={{
              minWidth: "300px",
              flex: "0 0 auto",
            }}
            key={index}
          >
            <div className="card-header text-white" style={{background:'#005C99'}}>
              <h5 className="mb-0">{plan.title}</h5>
            </div>
            <div className="card-body">
              <h2 className="card-title">{plan.price}</h2>
              <h6 className="card-title">{plan.features}</h6>
              <p className="text-muted">{plan.pack}</p>
              <h5>{plan.Ads}</h5>
              <p className="text-muted">{plan.text}</p>
              <h5>{plan.duration}</h5>

              <button className="btn w-100" style={{background:'#005C99' , color:'#fff'}}>Get Started</button>
            </div>
          </div>
        ))}
      </div>

      {/* Custom scrollbar hiding CSS */}
      <style>
        {`
          .row {
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
          }
          .row::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        `}
      </style>
    </div>
  );
};

export default Property;
