
// src/App.js
import React, { useState } from 'react';
import TopBar from './TopBar';
import BottomNavigation from './BottomNavigation';

import { FaHome, FaBuilding, FaPlusSquare, FaUser, FaEllipsisH } from 'react-icons/fa';

// import './FloatingBallMenu.css'
import Nopage from './Nopage';
import MoreComponent from './MoreComponent';
import MyProperty from './MyProperty';
import PropertyCards from './PropertyCards';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import AddProps from './AddProps';


const Main = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract phoneNumber and countryCode from location.state or localStorage
  const { phoneNumber: statePhoneNumber, countryCode: stateCountryCode } = location.state || {};
  const storedPhoneNumber = localStorage.getItem('phoneNumber');
  const storedCountryCode = localStorage.getItem('countryCode');

  const phoneNumber = statePhoneNumber || storedPhoneNumber;
  const countryCode = stateCountryCode || storedCountryCode;

  const handleAddProperty = async () => {
    if (!phoneNumber || !countryCode) {
      toast.error('Missing phone number or country code.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/store-data`, {
        phoneNumber: `${countryCode}${phoneNumber}`,
      });

      if (response.status === 201) {
        // toast.success(`User added successfully! PPC-ID: ${response.data.ppcId}`);
        
        // Navigate to AddPropertyForm page with PPC-ID and phone number
        setTimeout(() => {
          navigate('/add-form', {
            state: { ppcId: response.data.ppcId, phoneNumber: `${countryCode}${phoneNumber}` },
          });
        }, 100);
        
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Error adding user.');
      } else {
        toast.error('Error adding user. Please try again.');
      }
      console.error('Frontend Error:', error);
    }
  };

  // const handlefetchProperty = async () => {
  //   if (!phoneNumber || !countryCode) {
  //     toast.error('Missing phone number or country code.');
  //     return;
  //   }
  
  //   try {
  //     // Fetch user data from the API using phone number
  //     const response = await axios.get('http://localhost:5000/PPC/fetch-datas', {
  //       params: { phoneNumber: `${countryCode}${phoneNumber}` }
  //     });
  
  //     if (response.status === 200) {
  //       // Assuming the response contains users data, navigate to MyProperty page
  //       setTimeout(() => {
  //         navigate('/my', {
  //           state: { phoneNumber: `${countryCode}${phoneNumber}`, users: response.data.users },
  //         });
  //       }, 100);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       toast.error(error.response.data.message || 'Error fetching property.');
  //     } else {
  //       toast.error('Error fetching property. Please try again.');
  //     }
  //     console.error('Frontend Error:', error);
  //   }
  // };
  

  const handlefetchProperty = async () => {
    if (!phoneNumber || !countryCode) {
      toast.error('Missing phone number or country code.');
      return;
    }
  
    try {
      // Fetch user data from the API using phone number
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-datas`, {
        params: { phoneNumber: `${countryCode}${phoneNumber}` },
      });
  
      if (response.status === 200) {
        // Check if the response contains users data
        if (response.data && response.data.users && response.data.users.length > 0) {
          // If data is available, navigate to MyProperty page with the users' data
          setTimeout(() => {
            navigate('/my', {
              state: { phoneNumber: `${countryCode}${phoneNumber}`, users: response.data.users },
            });
          }, 100);
        } else {
          // If no users data, show a toast message indicating no data found
          toast.error('Data not found.');
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Error fetching property.');
      } else {
        toast.error('Error fetching property. Please try again.');
      }
      console.error('Frontend Error:', error);
    }
  };
  

  React.useEffect(() => {
    if (phoneNumber && countryCode) {
      // Save phoneNumber and countryCode to localStorage
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('countryCode', countryCode);
    } else {
      toast.error('Missing required information to add property.');
    }
  }, [phoneNumber, countryCode]);



  // Set default active content to 'bottomHome' for HomeContent
  const [activeContent, setActiveContent] = useState('bottomHome'); 

  // TopBar items with appropriate mapping to content
  const topBarItems = [
    { icon: 'bi-house-door', text: 'Py Property', content: 'topPyProperty' },
    { icon: 'bi-person', text: 'All Property', content: 'topAllProperty' },
    { icon: 'bi-envelope', text: 'Buyer List', content: 'topMBuyerList' },
    { icon: 'bi-envelope', text: 'Rent Property', content: 'topRentProperty' },
    { icon: 'bi-envelope', text: 'Used Cars', content: 'topUsedCars' },
    { icon: 'bi-envelope', text: 'Pm Groom', content: 'topPmGroom' },
    { icon: 'bi-envelope', text: 'Pm Bride', content: 'topPmBride' },
    { icon: 'bi-envelope', text: 'Feature Property', content: 'topFeatureProperty' },
    { icon: 'bi-envelope', text: 'Not Viewed Property', content: 'topNotViewedProperty' },
    { icon: 'bi-envelope', text: 'My Property', content: 'topMyProperty' },
    { icon: 'bi-envelope', text: 'Owner Menu', content: 'topOwnerMenu' },
    { icon: 'bi-envelope', text: 'Buyer Menu', content: 'topBuyerMenu' },
  ];

  // BottomNav items with appropriate mapping to content
  const bottomNavItems = [
    { icon: <FaHome />, text: 'Home', content: 'bottomHome' },
    { icon: <FaBuilding />, text: 'MyProperty', content: 'bottomProperty' , handler: handlefetchProperty},
    { icon: <FaPlusSquare />, text: 'AddProperty', content: 'bottomAdd', handler: handleAddProperty  },
    { icon: <FaUser />, text: 'Buyer', content: 'bottomBuyer' },
    { icon: <FaEllipsisH />, text: 'More', content: 'bottomMore' },
  ];
 
  const renderContent = () => {
    switch (activeContent) {
      case 'topPyProperty':return <Nopage />;
      // case 'topAllProperty':return <AddProperties />;
      case 'topMBuyerList': return <Nopage />;
      case 'topMBuyerList': return <Nopage />;
      case 'topRentProperty': return <Nopage />;
      case 'topUsedCars': return <Nopage />;
      case 'topPmGroom': return <Nopage />;
      case 'topPmBride': return <Nopage />;
      case 'topFeatureProperty': return <Nopage />;
      case 'topNotViewedProperty': return <Nopage />;
      case 'topOwnerMenu': return <Nopage />;
      case 'topBuyerMenu': return <Nopage />;
      case 'bottomHome': return <PropertyCards />;
      case 'bottomProperty': return <MyProperty />;
      case 'bottomAdd': return <AddProps />;
      case 'bottomBuyer': return <Nopage />;
      case 'bottomMore': return <MoreComponent />;
      default: return <Nopage />;
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <TopBar items={topBarItems} setActive={setActiveContent} activeItem={activeContent} />
      <div className="content" style={{ flex: 1, overflowY: 'auto' }}>
        {renderContent()} {/* Render content based on the active state */}
      </div>
      <div style={{position:'fixed', bottom:0}}>
      <BottomNavigation 
        items={bottomNavItems}
        // items={items}
        setActive={setActiveContent}
        activeItem={activeContent}
      />
      </div>
    </div>
  );
};

export default Main;
