import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import soon from "../Assets/coming.png"
const Building = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: '80vh',
        width: '100%',
        maxWidth: '450px',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <img
        src={soon}
        alt="Responsive"
        className="img-fluid"
        style={{ objectFit: 'cover', height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default Building;
