import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BiBed, BiBath, BiCar, BiMap, BiCalendar, BiUser, BiCube,
} from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineCurrencyRupee, MdElevator, MdOutlineChair } from "react-icons/md";
import { TbArrowLeftRight } from "react-icons/tb";
import { BsGraphUp } from "react-icons/bs";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { AiOutlineColumnWidth, AiOutlineColumnHeight } from "react-icons/ai";
import { TiContacts } from "react-icons/ti";
import { RiLayoutLine } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { MdApproval, MdTimer } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import { Card, Col, Row } from "react-bootstrap";

const Details = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const location = useLocation();
  const { ppcId } = location.state || {};
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetch-data?ppcId=${ppcId}`
        );
        setPropertyDetails(response.data.user);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setError("Failed to fetch property details.");
      } finally {
        setLoading(false);
      }
    };

    if (ppcId) fetchPropertyData();
  }, [ppcId]);

  if (loading) {
    return <p>Loading property details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!propertyDetails) {
    return <p>No property details found.</p>;
  }

  const images = propertyDetails.photos && propertyDetails.photos.length > 0
    ? propertyDetails.photos.map((photo) => `http://localhost:5000/${photo}`)
    : ["https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"];

  const propertDetails = [
    { heading: true, label: "Residential" },
    { icon: <AiOutlineColumnWidth />, label: "Length", value: propertyDetails.length },
    { icon: <AiOutlineColumnHeight />, label: "Breadth", value: propertyDetails.breadth },
    { icon: <RiLayoutLine />, label: "Total Area", value: propertyDetails.totalArea },
    { icon: <FaUserAlt />, label: "Ownership", value: propertyDetails.ownership },
    { icon: <MdApproval />, label: "Property Approved", value: propertyDetails.propertyApproved },
    { icon: <MdTimer />, label: "Property Age", value: propertyDetails.propertyAge },
    { icon: <BsBank />, label: "Bank Loan", value: propertyDetails.bankLoan },
    { heading: true, label: "Product Details" },
    { icon: <MdOutlineCurrencyRupee />, label: "Price", value: propertyDetails.price },
    { icon: <BiBed />, label: "Bedrooms", value: propertyDetails.bedrooms },
    { icon: <BiBath />, label: "Bathrooms", value: propertyDetails.bedrooms },
    { icon: <BiCube />, label: "Floors", value: propertyDetails.floorNo },
    { icon: <TbArrowLeftRight />, label: "Facing", value: propertyDetails.facing },
    { icon: <BiCar />, label: "Car Park", value: propertyDetails.carParking },
    { icon: <MdElevator />, label: "Lift", value: propertyDetails.lift },
    { icon: <MdOutlineChair />, label: "Furnished", value: propertyDetails.furnished },
    { icon: <BsGraphUp />, label: "Sale Mode", value: propertyDetails.salesMode },
    { icon: <BiUser />, label: "Posted By", value: propertyDetails.postedBy },
    { icon: <AiOutlineEye />, label: "Views", value: '5' },
    { icon: <BiCalendar />, label: "Posted On", value: '1 month' },
    { heading: true, label: "Description" },
    { icon: '', label: "", value: propertyDetails.description },
    { heading: true, label: "Property Location Info" },
    { icon: <BiMap />, label: "Location", value: propertyDetails.city },
    { heading: true, label: "Contact Info" },
  ];

  return (
    <div className="container mt-4" style={{
      fontFamily: "Inter, sans-serif",
      height: "100vh",
      overflowY: "scroll",
      width: '450px',
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: '100px'
    }}>
      <style>
        {`
          .container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      
      <div className="mb-4">
        <Swiper loop={true}>
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "400px",
                  width: "500px",
                  overflow: "hidden",
                  borderRadius: "8px",
                  margin: "auto",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={image}
                  alt={`Property Image ${index + 1}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      
      <h4 className="fw-bold " style={{ color: "#000000", fontFamily: "Inter, sans-serif", fontSize: '16px' }}>
        Make an offer
      </h4>
      <form onSubmit={handleSubmit} className="d-flex align-items-center">
        <input
          type="tel"
          placeholder="Make an offer"
          style={{
            padding: "8px 12px",
            borderRadius: "4px",
            border: "1px solid #30747F",
            marginRight: "10px",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "8px 12px",
            borderRadius: "4px",
            border: "1px solid #30747F",
            backgroundColor: "#30747F",
            color: "#fff",
          }}
        >
          Submit
        </button>
      </form>



      <div className="row g-3 mt-3">
    {propertDetails.map((detail, index) =>
      detail.heading ? (
        <div key={index} className="col-12">
          <h4 className="fw-bold" style={{ color: "#000000", fontFamily: "Inter, sans-serif", fontSize: '16px' }}>
            {detail.label}
          </h4>
        </div>
      ) : (
        <div key={index} className="col-md-6">
          <div
            className="d-flex align-items-center border rounded p-3"
            style={{ backgroundColor: "#F9F9F9", fontFamily: "Inter, sans-serif" }}
          >
            <span
              className="me-3 fs-3"
              style={{ color: "#30747F" }}
            >
              {detail.icon}
            </span>
            <div>
              <h6 className="mb-1" style={{ color: "#000000", fontFamily: "Inter, sans-serif" }}>
                {detail.label}
              </h6>
              <p
                className="mb-0"
                style={{ color: "#00000029" }}
              >
                {detail.value}
              </p>
            </div>
          </div>
        </div>
      )
    )}

    <div className="card mt-2 pb-4">
      <Row className="g-4 mt-2">
        {["interest", "contact", "shortlist", "report", "help1"].map((type, index) => (
          <Col key={index} md={2} sm={4} xs={6}>
            <Card className="p-3">
              <Card.Img 
                variant="top" 
                className="mt-3" 
                src={`https://www.ppcpondy.com/img/img/3/${type}.png`} 
                alt={type.replace(/^\w/, (c) => c.toUpperCase()).replace("1", " ")} 
                style={{width:"50px",height:"50px",marginLeft:"35%"}} 
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>

    <button className="btn rounded-1" style={{ background: 'transparent', border: '1px solid #30747F', color: '#30747F' }}>
      <TiContacts /> View owner contact details
    </button>
  </div>


      {showModal && (
        <div
          className="modal show d-block"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onClick={closeModal}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  src={images[currentImageIndex]}
                  alt={`Large Property Image`}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="modal-footer">
                <p className="text-muted">Total Images: {images.length}</p>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;













// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import {
//   BiBed, BiBath, BiCar, BiMap, BiCalendar, BiUser, BiCube,
// } from "react-icons/bi";
// import { AiOutlineEye } from "react-icons/ai";
// import { MdOutlineCurrencyRupee , MdElevator, MdOutlineChair } from "react-icons/md";
// import { TbArrowLeftRight } from "react-icons/tb";
// import { BsGraphUp } from "react-icons/bs";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { AiOutlineColumnWidth, AiOutlineColumnHeight } from "react-icons/ai";
// import { TiContacts } from "react-icons/ti";

// import { RiLayoutLine } from "react-icons/ri";
// import { FaUserAlt } from "react-icons/fa";
// import { MdApproval, MdTimer } from "react-icons/md";
// import { BsBank } from "react-icons/bs";
// // Import Inter font if using npm
// import "@fontsource/inter/400.css";
// import "@fontsource/inter/500.css";

// // if no image from api set default image
// // import fallbackImage from '../Assets/default.png'
// const Details = () => {
//   const [showModal, setShowModal] = useState(false);
//       const [currentImageIndex, setCurrentImageIndex] = useState(0);
//       const handleSubmit = (e) => {
//             e.preventDefault();
//           };
//       const handleImageClick = (index) => {
//             setCurrentImageIndex(index);
//             setShowModal(true);
//           };
        
//           const closeModal = () => {
//             setShowModal(false);
//           };

//   const location = useLocation();
//   const { ppcId } = location.state || {};
//   const [propertyDetails, setPropertyDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPropertyData = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:5000/PPC/fetch-data?ppcId=${ppcId}`
//         );
//         setPropertyDetails(response.data.user);
//       } catch (error) {
//         console.error("Error fetching property data:", error);
//         setError("Failed to fetch property details.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (ppcId) fetchPropertyData();
//   }, [ppcId]);

//   if (loading) {
//     return <p>Loading property details...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   if (!propertyDetails) {
//     return <p>No property details found.</p>;
//   }
//   // const images = [
//   //       "https://cms.interiorcompany.com/wp-content/uploads/2024/08/traditional-small-village-house-design.jpg",
//   //       "https://i.pinimg.com/550x/da/e4/10/dae4108611098ac3b803e94cb2179033.jpg",
//   //       "image3.jpg",
//   //     ]; // Replace with your image URLs

//   const images = propertyDetails.photos.map(
//     (photo) => `http://localhost:5000/${photo}`
//   );


//   const propertDetails = [
//         { heading: true, label: "Residential" }, // Heading 1
    
//         {icon: <AiOutlineColumnWidth />, label: "Length", value: propertyDetails.length},
//         { icon: <AiOutlineColumnHeight />, label: "Breadth", value: propertyDetails.breadth },
//         { icon: <RiLayoutLine />, label: "Total Area", value: propertyDetails.totalArea },
//         { icon: <FaUserAlt />, label: "Ownership", value: propertyDetails.ownership},
//         { icon: <MdApproval />, label: "Property Approved", value: propertyDetails.propertyApproved},
//         { icon: <MdTimer />, label: "Property Age", value: propertyDetails.propertyAge },
//         { icon: <BsBank />, label: "Bank Loan", value: propertyDetails.bankLoan },
//         { heading: true, label: "Product Details" }, // Heading 2
    
//         { icon: <MdOutlineCurrencyRupee   />, label: "Price", value: propertyDetails.price },
//         { icon: <BiBed />, label: "Bedrooms", value: propertyDetails.bedrooms },
//         { icon: <BiBath />, label: "Bathrooms", value: propertyDetails.bedrooms },
//         { icon: <BiCube />, label: "Floors", value: propertyDetails.floorNo },
//         { icon: <TbArrowLeftRight />, label: "Facing", value: propertyDetails.facing },
//         { icon: <BiCar />, label: "Car Park", value: propertyDetails.carParking },
//         { icon: <MdElevator />, label: "Lift", value: propertyDetails.lift },
//         { icon: <MdOutlineChair />, label: "Furnished", value: propertyDetails.furnished },
//         { icon: <BsGraphUp />, label: "Sale Mode", value: propertyDetails.salesMode },
//         { icon: <BiUser />, label: "Posted By", value: propertyDetails.postedBy },
//         { icon: <AiOutlineEye />, label: "Views", value: '5' },
//         { icon: <BiCalendar />, label: "Posted On", value: '1 month' },
//         { heading: true, label: "Description" }, // Heading 3
//         { icon:'', label: "", value: propertyDetails.description },
    
//         { heading: true, label: "Property Location Info" }, // Heading 4
    
//         { icon: <BiMap />, label: "Location", value: propertyDetails.city },
//         { heading: true, label: "Contact Info" }, // Heading 5
//       ];
    
//   return (
//  <div className="container mt-4"  style={{
//           fontFamily: "Inter, sans-serif",
//           height: "100vh", // Set height to 90% of viewport height
//           overflowY: "scroll", // Enable vertical scrolling
//           width:'450px',
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           marginBottom:'100px'
//         }}
//         >
//              <style>
//           {`
//             .container::-webkit-scrollbar {
//               display: none; /* Hide scrollbar in Webkit browsers */
//             }
//           `}
//         </style>
//         {/* Image Swiper */}
//      {/* Image Swiper without Controls and Indicators */}
//      <div className="mb-4">
//           <Swiper loop={true}>
//             {images.map((image, index) => (
//               <SwiperSlide key={index}>
//                 <div
//                   className="d-flex justify-content-center align-items-center"
//                   style={{
//                     height: "200px",
//                     width: "200px",
//                     overflow: "hidden",
//                     borderRadius: "8px",
//                     margin: "auto",
//                     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow for the image
//                     cursor: "pointer", // Add cursor pointer for image click
//                   }}
//                   onClick={() => handleImageClick(index)}
//                 >
//                   <img
//                     src={images || "https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"}
//                     // alt={`Property Image ${index + 1}`}
//                     style={{
//                       height: "100%",
//                       width: "100%",
//                       objectFit: "cover",
//                     }}
//                   />
//                 </div>
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//    {/* <img src="https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg" alt="" /> */}
  
//         {/* Property Title */}
//         {/* <div className="text-center mb-4">
//           <h1 className="fw-bold" style={{ color: "#000000",fontFamily: "Inter, sans-serif", }}>
//             Luxurious Villa
//           </h1>
//         </div> */}
  
//         {/* Property Details */}
//         <div className="row g-3">
//           {propertDetails.map((detail, index) =>
//             detail.heading ? (
//               // Render heading row
//               <div key={index} className="col-12">
//                 <h4 className="fw-bold" style={{ color: "#000000",fontFamily: "Inter, sans-serif",fontSize:'16px' }}>
//                   {detail.label}
//                 </h4>
//               </div>
//             ) : (
//               // Render detail item
//               <div key={index} className="col-md-6">
//                 <div
//                   className="d-flex align-items-center border rounded p-3"
//                   style={{ backgroundColor: "#F9F9F9", fontFamily: "Inter, sans-serif", }}
//                 >
//                   <span
//                     className="me-3 fs-3"
//                     style={{ color: "#30747F" }}
//                   >
//                     {detail.icon}
//                   </span>
//                   <div>
//                     <h6 className="mb-1" style={{ color: "#000000",fontFamily: "Inter, sans-serif", }}>
//                       {detail.label}
//                     </h6>
//                     <p
//                       className="mb-0"
//                       style={{ color: "#00000029" }}
//                     >
//                       {detail.value}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             )
//           )}
//      <button className="btn rounded-1"  style={{background:'transparent', border:'1px solid #30747F', color:'#30747F'}}><TiContacts />view owner contact details</button>
  
//         </div>
//         <h4 className="fw-bold mt-3" style={{ color: "#000000",fontFamily: "Inter, sans-serif", fontSize:'16px' }}>
//                       Make an offer
//                     </h4>
//         <form onSubmit={handleSubmit} className="d-flex align-items-center">
//             <input
//               type="tel"
              
//               placeholder="make an offer"
//               style={{
//                 padding: "8px 12px",
//                 borderRadius: "4px",
//                 border: "1px solid #30747F",
//                 marginRight: "10px",
//               }}
//             />
//             <button
//               type="submit"
//               style={{
//                 padding: "8px 12px",
//                 borderRadius: "4px",
//                 border: "1px solid #30747F",
//                 backgroundColor: "#30747F",
//                 color: "#fff",
//               }}
//             >
//               Submit
//             </button>
//           </form>

//                 {/* Modal to view large image */}
//         {showModal && (
//           <div
//             className="modal show d-block"
//             style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
//             onClick={closeModal}
//           >
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-body">
//                   <img
//                     src={images[currentImageIndex]}
//                     alt={`Large Property Image`}
//                     style={{ width: "100%", height: "auto" }}
//                   />
//                 </div>
//                 <div className="modal-footer">
//                   <p className="text-muted">Total Images: {images.length}</p>
//                   <button
//                     type="button"
//                     className="btn btn-secondary"
//                     onClick={closeModal}
//                   >
//                     Close
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//   );
// };

// export default Details;





// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { BiBed, BiBath, BiCar, BiMap } from "react-icons/bi";
// import { AiOutlineEye } from "react-icons/ai";

// const Details = () => {
//   const location = useLocation();
//   const { ppcId } = location.state || {};
//   const [propertyDetails, setPropertyDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPropertyData = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:5000/PPC/fetch-data?ppcId=${ppcId}`
//         );
//         setPropertyDetails(response.data.user);
//       } catch (error) {
//         console.error("Error fetching property data:", error);
//         setError("Failed to fetch property details.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (ppcId) fetchPropertyData();
//   }, [ppcId]);

//   if (loading) {
//     return <p>Loading property details...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   if (!propertyDetails) {
//     return <p>No property details found.</p>;
//   }

//   const images = propertyDetails.photos.map(
//     (photo) => `http://localhost:5000/${photo}`
//   );

//   return (
//     <div
//       className="container mt-4 p-4 rounded-3"
//       style={{
//         fontFamily: "Inter, sans-serif",
//         boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//         background: "#fff",
//         maxWidth: "800px",
//       }}
//     >
//       {/* Image Swiper */}
//       <div className="mb-4">
//         <Swiper loop={true}>
//           {images.length > 0
//             ? images.map((image, index) => (
//                 <SwiperSlide key={index}>
//                   <div
//                     style={{
//                       height: "400px",
//                       borderRadius: "8px",
//                       overflow: "hidden",
//                       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                     }}
//                   >
//                     <img
//                       src={image}
//                       alt={`Property Image ${index + 1}`}
//                       style={{
//                         height: "100%",
//                         width: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   </div>
//                 </SwiperSlide>
//               ))
//             : (
//               <SwiperSlide>
//                 <img
//                   src="https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"
//                   alt="Default"
//                   style={{
//                     height: "100%",
//                     width: "100%",
//                     objectFit: "cover",
//                   }}
//                 />
//               </SwiperSlide>
//             )}
//         </Swiper>
//       </div>

//       {/* Property Details */}
//       <h4 className="fw-bold text-primary">Property Details</h4>
//       <div className="row g-3 mt-3">
//         <div className="col-6">
//           <p>
//             <strong>Owner:</strong> {propertyDetails.ownerName}
//           </p>
//           <p>
//             <strong>Price:</strong> ₹{propertyDetails.price}
//           </p>
//           <p>
//             <strong>Bedrooms:</strong> {propertyDetails.bedrooms} <BiBed />
//           </p>
//           <p>
//             <strong>Furnished:</strong> {propertyDetails.furnished}
//           </p>
//         </div>
//         <div className="col-6">
//           <p>
//             <strong>Location:</strong> {propertyDetails.rentalPropertyAddress}{" "}
//             <BiMap />
//           </p>
//           <p>
//             <strong>City:</strong> {propertyDetails.city}
//           </p>
//           <p>
//             <strong>State:</strong> {propertyDetails.state}
//           </p>
//           <p>
//             <strong>Country:</strong> {propertyDetails.country}
//           </p>
//         </div>
//         <div className="col-12">
//           <p>
//             <strong>Description:</strong> {propertyDetails.description}
//           </p>
//         </div>
//       </div>

//       {/* Contact Information */}
//       <button
//         className="btn btn-primary w-100 mt-4"
//         style={{
//           backgroundColor: "#30747F",
//           borderColor: "#30747F",
//         }}
//       >
//         <AiOutlineEye /> View Owner Contact Details
//       </button>
//     </div>
//   );
// };

// export default Details;
