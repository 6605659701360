// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaHome, FaInfoCircle, FaCogs, FaEnvelope, FaBuilding, FaLightbulb, FaPhone, FaStar, FaShare, FaUserCircle, FaRegAddressCard, FaRocket, FaShieldAlt, FaUsers } from 'react-icons/fa';
// import logo from "../Assets/ppc logo.jpg"
// import { FaRegBell } from "react-icons/fa6";

// function Navbar() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const closeSidebar = () => {
//     setIsSidebarOpen(false);
//   };

//   return (
//     <div className="d-flex" style={{fontFamily: "Inter, sans-serif",}}>
//       {/* Sidebar */}
//       <div
//         className={`position-fixed bg-light border-end ${
//           isSidebarOpen ? 'd-block' : 'd-none'
//         }`}
//         style={{
//           width: '300px',
//           Height: '100vh',
//           overflowY: 'auto',
//           transition: 'left 0.3s ease',
//           zIndex: 2000,
//         }}
//       >
//         <button
//           className="btn btn-close position-absolute top-0 end-0 m-2"
//           onClick={toggleSidebar}
//           aria-label="Close Sidebar"
//         ></button>
//   <ul className="nav flex-column p-0">
//     <li style={{background:'#30747F'}}>
//     <div className='d-flex align-items-center p-2'>
//   <img src={logo} alt="" style={{height:'80px', width:'80px'}} className='mb-2 mb-md-0 rounded-4'/>
//   <div className='ml-md-3 ml-2' style={{ marginLeft: '5px' }}>
//     <h6 style={{color:'white'}}>Pondy Property</h6>
//     <p style={{color:'white', fontSize:'13px'}}>Buy and sell your Property in Pondicherry</p>
//   </div>
// </div>    
// </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaHome className="me-2" style={{ color: '#30747F' }} /> Home
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaBuilding className="me-2" style={{ color: '#30747F' }} /> My Property
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaLightbulb className="me-2" style={{ color: '#30747F' }} /> My Plan
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaUserCircle className="me-2" style={{ color: '#30747F' }} /> My Profile
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaRocket className="me-2" style={{ color: '#30747F' }} /> Upgrade Membership
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaCogs className="me-2" style={{ color: '#30747F' }} /> More
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaPhone className="me-2" style={{ color: '#30747F' }} /> Contact Us
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaInfoCircle className="me-2" style={{ color: '#30747F' }} /> About Us
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaRegAddressCard className="me-2" style={{ color: '#30747F' }} /> More App
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaShare className="me-2" style={{ color: '#30747F' }} /> Share App
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaStar className="me-2" style={{ color: '#30747F' }} /> Rate App
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaShieldAlt className="me-2" style={{ color: '#30747F' }} /> Business Opportunity
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaUsers className="me-2" style={{ color: '#30747F' }} /> Our Support
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
//           <FaEnvelope className="me-2" style={{ color: '#30747F' }} /> Login
//         </a>
//       </li>
//     </ul>  
//       </div>

//       {/* Main Content */}
//       <div className="flex-grow-1">
//         {/* Navbar */}
//         <nav
//           className="navbar navbar-light bg-light d-flex align-items-center justify-content-between px-3"
//           style={{ width: '100%', height: '60px' }}
//         >
//           <button className="btn" onClick={toggleSidebar}>
//             ☰
//           </button>
//           <span className="navbar-brand mb-0 text-center mx-auto">
//             Pondy Property
//           </span>
//           <button className="btn border-0" style={{fontWeight:'bold'}}><FaRegBell color='#30747F' size={24}/>
//           </button>
//         </nav>       
//       </div>
//     </div>
//   );
// }

// export default Navbar;


import React, { useState, useEffect, useRef } from 'react';
import {
  FaHome,
  FaBuilding,
  FaLightbulb,
  FaUserCircle,
  FaRocket,
  FaCogs,
  FaPhone,
  FaInfoCircle,
  FaRegAddressCard,
  FaShare,
  FaStar,
  FaShieldAlt,
  FaUsers,
  FaEnvelope,
  FaRegBell,
} from 'react-icons/fa';
import logo from "../Assets/ppc logo.jpg"

const SidebarApp = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setSidebarOpen(false);

  // Close sidebar on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isSidebarOpen]);

  return (
    <div className="d-flex" style={{ fontFamily: 'Inter, sans-serif' }}>
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`position-fixed bg-light border-end ${
          isSidebarOpen ? 'd-block' : 'd-none'
        }`}
        style={{
          width: '300px',
          Height: '100vh',
          overflowY: 'auto',
          transition: 'left 0.3s ease',
          zIndex: 2000,
        }}
      >
        <button
          className="btn btn-close position-absolute top-0 end-0 m-2"
          onClick={toggleSidebar}
          aria-label="Close Sidebar"
        ></button>
       <ul className="nav flex-column p-0">
    <li style={{background:'#30747F'}}>
    <div className='d-flex align-items-center p-2'>
  <img src={logo} alt="" style={{height:'80px', width:'80px'}} className='mb-2 mb-md-0 rounded-4'/>
  <div className='ml-md-3 ml-2' style={{ marginLeft: '5px' }}>
    <h6 style={{color:'white'}}>Pondy Property</h6>
    <p style={{color:'white', fontSize:'13px'}}>Buy and sell your Property in Pondicherry</p>
  </div>
</div>    
</li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaHome className="me-2" style={{ color: '#30747F' }} /> Home
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaBuilding className="me-2" style={{ color: '#30747F' }} /> My Property
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaLightbulb className="me-2" style={{ color: '#30747F' }} /> My Plan
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaUserCircle className="me-2" style={{ color: '#30747F' }} /> My Profile
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaRocket className="me-2" style={{ color: '#30747F' }} /> Upgrade Membership
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaCogs className="me-2" style={{ color: '#30747F' }} /> More
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaPhone className="me-2" style={{ color: '#30747F' }} /> Contact Us
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaInfoCircle className="me-2" style={{ color: '#30747F' }} /> About Us
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaRegAddressCard className="me-2" style={{ color: '#30747F' }} /> More App
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaShare className="me-2" style={{ color: '#30747F' }} /> Share App
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaStar className="me-2" style={{ color: '#30747F' }} /> Rate App
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaShieldAlt className="me-2" style={{ color: '#30747F' }} /> Business Opportunity
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaUsers className="me-2" style={{ color: '#30747F' }} /> Our Support
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" style={{ color: 'black' }} href="/mobileviews" onClick={closeSidebar}>
          <FaEnvelope className="me-2" style={{ color: '#30747F' }} /> Login
        </a>
      </li>
    </ul>  
      </div>

      {/* Main Content */}
      <div className="flex-grow-1">
        {/* Navbar */}
        <nav
          className="navbar navbar-light bg-light d-flex align-items-center justify-content-between px-3"
          style={{ width: '100%', height: '60px' }}
        >
          <button className="btn" onClick={toggleSidebar}>
            ☰
          </button>
          <span className="navbar-brand mb-0 text-center mx-auto">
            Pondy Property
          </span>
          <button className="btn border-0" style={{ fontWeight: 'bold' }}>
            <FaRegBell color="#30747F" size={24} />
          </button>
        </nav>
      </div>
    </div>
  );
};

export default SidebarApp;
