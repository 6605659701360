import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MoreComponent.css'; // Custom CSS for additional styles

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('myAccount');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="container-fluid mt-4 p-0 w-100%" style={{fontFamily: "Inter, sans-serif"}}>
            <ul className="nav nav-tabs">
                <li className="nav-item">
             
                    <button
                        className={`nav-link ${activeTab === 'myAccount' ? 'active' : ''}`}
                        style={activeTab === 'myAccount' ? { backgroundColor: '#30747F', color: 'white' } : {color:'black'}}
                        onClick={() => handleTabClick('myAccount')}
                    >
                        My Account
                    </button>
                </li>
                <li className="nav-item">
                <button
                        className={`nav-link ${activeTab === 'buyerMenu' ? 'active' : ''}`}
                        style={activeTab === 'buyerMenu' ? { backgroundColor: '#30747F', color: 'white' } : {color:'black'}}
                        onClick={() => handleTabClick('buyerMenu')}
                    >
                        Buyer Menu
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'ownerMenu' ? 'active' : ''}`}
                        style={activeTab === 'ownerMenu' ? { backgroundColor: '#30747F', color: 'white' } : {color:'black'}}
                        onClick={() => handleTabClick('ownerMenu')}
                    >
                        Owner Menu
                    </button>
                </li>
            </ul>

            <div className="tab-content mt-3">
                {activeTab === 'myAccount' && (
                    <div className="tab-pane active" style={{ maxHeight: '400px', overflowY: 'scroll', scrollbarWidth: 'none' }}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="m-0" style={{color:'#30747F'}}>My Account</h3>
                            <img src="https://via.placeholder.com/50" alt="My Account" className="rounded" />
                        </div>
                        <ul className="list-group custom-list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>Add New Property</span>
                                </div>
                                <span className="text-primary">&gt;</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>My Property</span>
                                </div>
                                <span className="badge bg-primary rounded-pill">1</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>My Profile</span>
                                </div>
                                <span className="text-primary">&gt;</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>My Plan</span>
                                </div>
                                <span className="text-primary">&gt;</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>Notification</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="notificationToggle" />
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>Removed Property</span>
                                </div>
                                <span className="badge bg-primary rounded-pill">1</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                                <div className="d-flex align-items-center">
                                    <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                    <span>Expired Property</span>
                                </div>
                                <span className="badge bg-primary rounded-pill">0</span>
                            </li>
                        </ul>
                    </div>
                )}
                {activeTab === 'buyerMenu' && (
                     <div className="tab-pane active" style={{ maxHeight: '400px', overflowY: 'scroll', scrollbarWidth: 'none' }}>
                     <div className="d-flex justify-content-between align-items-center mb-3">
                         <h3 className="m-0" style={{color:'#30747F'}}>Buyer Menu</h3>
                         <img src="https://via.placeholder.com/50" alt="My Account" className="rounded" />
                     </div>
                     <ul className="list-group custom-list-group">
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Add New Property</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">1</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Profile</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Plan</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Notification</span>
                             </div>
                             <div className="form-check form-switch">
                                 <input className="form-check-input" type="checkbox" id="notificationToggle" />
                             </div>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Removed Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">1</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Expired Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">0</span>
                         </li>
                     </ul>
                 </div>
                )}
                {activeTab === 'ownerMenu' && (
                     <div className="tab-pane active" style={{ maxHeight: '400px', overflowY: 'scroll', scrollbarWidth: 'none' }}>
                     <div className="d-flex justify-content-between align-items-center mb-3">
                         <h3 className="m-0" style={{color:'#30747F'}}>Owner Menu</h3>
                         <img src="https://via.placeholder.com/50" alt="My Account" className="rounded" />
                     </div>
                     <ul className="list-group custom-list-group">
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Add New Property</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">1</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Profile</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>My Plan</span>
                             </div>
                             <span className="text-primary">&gt;</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Notification</span>
                             </div>
                             <div className="form-check form-switch">
                                 <input className="form-check-input" type="checkbox" id="notificationToggle" />
                             </div>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Removed Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">1</span>
                         </li>
                         <li className="list-group-item d-flex justify-content-between align-items-center custom-list-item">
                             <div className="d-flex align-items-center">
                                 <img src="https://via.placeholder.com/30" alt="icon" className="me-3" />
                                 <span>Expired Property</span>
                             </div>
                             <span className="badge bg-primary rounded-pill">0</span>
                         </li>
                     </ul>
                 </div>
                )}
            </div>
        </div>
    );
};

export default Tabs;
