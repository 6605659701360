import React from 'react'
import App from '../App'
import Nopage from './Nopage'
import Building from './Building'
import MobileViews from './MoblieViews'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Login'
import AddProps from './AddProps'
import MyProperty from './MyProperty'
import EditForm from './EditForm'
import Property from './PropertyCard';
import Details from './Details'


export default function RouterPage() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<App />} />
    <Route path="/mobileviews" element={<MobileViews />} />
    <Route path="/login" element={<Login />} />
    <Route path="/Construction" element={<Building />} />
    <Route path="*" element={<Nopage />} />
    <Route path='/my' element={<MyProperty />} />
        <Route path='/property' element={<Property />} />
        <Route path='/add-form' element={<AddProps/>}/>
        <Route path='/edit-form' element={<EditForm />} />
        <Route path='/detail' element={<Details />} />
    </Routes>
    </BrowserRouter>  )
}
