

// import React, { useState, useEffect } from "react";
// import { FaRulerCombined, FaBed, FaUser, FaCalendarAlt, FaEye, FaCamera , FaUserAlt , FaRupeeSign  } from "react-icons/fa";
// import { GoSearch } from "react-icons/go";
// import { Helmet } from "react-helmet";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import axios from "axios"; // Import axios for API calls

// const PropertyCards = () => {
//   // State to store fetched properties
//   const [properties, setProperties] = useState([]);

//   // Fetch properties data from the API
//   useEffect(() => {
//     const fetchProperties = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-all-data`); // API call
//         setProperties(response.data.users); // Set fetched properties into state
//       } catch (error) {
//         console.error("Error fetching properties:", error);
//       }
//     };
    
//     fetchProperties();
//   }, []); // Empty dependency array to run only once when the component mounts

//   return (
//     <Container fluid className="p-3">
//       <Helmet>
//         <title>Pondy Property | Properties</title>
//       </Helmet>
//       <Row className="g-3">
//         <Col lg={12} className="d-flex align-items-center justify-content-center">
//           {/* <div className="d-flex mt-3 justify-content-center align-items-center"> */}
//             {/* <div className="search-icon" 
//                           style={{position:"fixed",right:"38%",bottom:"15%",backgroundColor:"#2F747F",color:"white",borderRadius:"50%",zIndex:"1",height:"50px",width:"50px"}} 
// >
//               <GoSearch  style={{fontSize:"25px"}} />
//             </div> */}

// <div className=" d-flex flex-column justify-content-center align-items-center" 
//             style={{height:'50px', width:'50px', background:'#2F747F', borderRadius:'50%',position:"fixed",right:"38%",bottom:"15%",zIndex:"1"}}>
//               <GoSearch fontSize={24} color="#fff"/>
//  </div>
//             <div
//               style={{
//                 width: "100%",
//               }}
//             >
//                <div style={{ maxHeight: '500px', overflowY: 'scroll', scrollbarWidth: 'none' }}>

//               {properties.length > 0 ? (
//                 properties.map((property) => (
//    <div key={property._id}
//           className="card mb-3 shadow p-1"
//           style={{ width: '100%', minWidth: '400px', height: 'auto', background:'#F9F9F9' }} // Make width responsive
//           >
//           <div className="row g-0">
//             {/* Image Section */}
//             <div className="col-md-4 d-flex flex-column justify-content-between align-items-center">
//               {/* Top: PUC Code */}
//               <div
//                 className="text-white py-1 px-2 text-center"
//                 style={{ width: '100%' , background:"#2F747F"}}
//               >
//                 PUC- {property.ppcId}
//               </div>
//               {/* Middle: Image */}
//               <div className="img-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
//                 <img
//                  src={
//                   property.photos && property.photos.length > 0
//                             ? `http://localhost:5000/${property.photos[0]}`
//                              : "https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"
//                         }
//                   className="img-fluid"
//                   style={{
//                     width: '100%',
//                     height: '120px',
//                     objectFit: 'cover', // Try 'contain' if you want the full image visible without clipping
//                   }}
//                 />
//               </div>
//               {/* Bottom: Incomplete */}
//               <div className="d-flex justify-content-between mt-2 w-100">
//               <span className="p-2" style={{ color:'#fff', background:'#2F747F', fontSize:'12px' , borderRadius:'10% 75% 30% 10%'}}> <FaCamera className="me-1"/> 1</span>
//               <span className="p-2" style={{ color:'#fff',  background:'#2F747F', fontSize:'12px', borderRadius:'75% 20% 30% 10%'}}>  <FaEye className="me-1" />1</span>
//                </div>

//             </div>
          
//             {/* Content Section */}
//             <div className="col-md-8 p-1">
//             <p className="m-0">{property.propertyMode}</p>
//             <p>{property.propertyType}</p>
//             <p>{property.city}</p>
//               <div className="card-body p-2 d-flex flex-column justify-content-center">
//                 {/* Align Icons and Text */}
//                 <div className="row">
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                     <FaRulerCombined className="me-2"color="#2F747F" /> <span style={{fontSize:'14px', color:'#555555'}}>{property.totalArea}</span>
//                   </div>
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                     <FaBed className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.bedrooms}</span>
//                   </div>
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                     <FaUserAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.ownership}</span>
//                   </div>
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                     <FaCalendarAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.bestTimeToCall}</span>
//                   </div>
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                     <FaRupeeSign className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.price}</span>
//                   </div>
//                   <div className="col-6 d-flex align-items-center mt-1 mb-1">
//                   <p className="m-0" style={{color:'#2F747F', fontSize:'14px'}}> Negotiation : <span style={{color:'#555555'}}> {property.negotiation}</span></p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           </div>

//                 ))
//               ) : (
//                 <p>Loading properties...</p> // Show a loading message until data is fetched
//               )}
//               </div>

//             </div>
//           {/* </div> */}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default PropertyCards;




import React, { useState, useEffect } from "react";
import { FaRulerCombined, FaBed, FaUser, FaCalendarAlt, FaEye, FaCamera, FaUserAlt, FaRupeeSign } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios"; // Import axios for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate

const PropertyCards = () => {
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate(); // Initialize navigate function

  // Fetch properties data from the API
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-all-data`); // API call
        setProperties(response.data.users); // Set fetched properties into state
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    
    fetchProperties();
  }, []); // Empty dependency array to run only once when the component mounts

  // Handle card click to navigate to details page
  const handleCardClick = (ppcId) => {
    navigate("/detail", { state: { ppcId } }); // Passing ppcId to the details page
  };

  return (
    <Container fluid className="p-3">
      <Helmet>
        <title>Pondy Property | Properties</title>
      </Helmet>
      <Row className="g-3">
        <Col lg={12} className="d-flex align-items-center justify-content-center">
          <div className=" d-flex flex-column justify-content-center align-items-center" 
            style={{height:'50px', width:'50px', background:'#2F747F', borderRadius:'50%',position:"fixed",right:"38%",bottom:"15%",zIndex:"1"}}>
            <GoSearch fontSize={24} color="#fff"/>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ maxHeight: '500px', overflowY: 'scroll', scrollbarWidth: 'none' }}>
              {properties.length > 0 ? (
                properties.map((property) => (
                  <div 
                    key={property._id}
                    className="card mb-3 shadow p-1"
                    style={{ width: '100%', minWidth: '400px', height: 'auto', background:'#F9F9F9' }}
                    onClick={() => handleCardClick(property.ppcId)} // Add onClick event to handle click
                  >
                    <div className="row g-0">
                      {/* Image Section */}
                      <div className="col-md-4 d-flex flex-column justify-content-between align-items-center">
                        <div className="text-white py-1 px-2 text-center" style={{ width: '100%' , background:"#2F747F"}}>
                          PUC- {property.ppcId}
                        </div>
                        <div className="img-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                          <img
                            src={property.photos && property.photos.length > 0
                              ? `http://localhost:5000/${property.photos[0]}`
                              : "https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"
                            }
                            className="img-fluid"
                            style={{
                              width: '100%',
                              height: '100px',
                              objectFit: 'cover', // Try 'contain' if you want the full image visible without clipping
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-between mt-2 w-100">
                          <span className="p-2" style={{ color:'#fff', background:'#2F747F', fontSize:'12px', borderRadius:'10% 75% 30% 10%'}}> <FaCamera className="me-1"/> 1</span>
                          <span className="p-2" style={{ color:'#fff', background:'#2F747F', fontSize:'12px', borderRadius:'75% 20% 30% 10%'}}>  <FaEye className="me-1" />1</span>
                        </div>
                      </div>
                      {/* Content Section */}
                      <div className="col-md-8 p-1">
                        <p className="m-0">{property.propertyMode}</p>
                        <p>{property.propertyType}</p>
                        <p>{property.city}</p>
                        <div className="card-body p-2 d-flex flex-column justify-content-center">
                          <div className="row">
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaRulerCombined className="me-2" color="#2F747F" /> <span style={{fontSize:'14px', color:'#555555'}}>{property.totalArea}</span>
                            </div>
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaBed className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.bedrooms}</span>
                            </div>
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaUserAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.ownership}</span>
                            </div>
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaCalendarAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.bestTimeToCall}</span>
                            </div>
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaRupeeSign className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{property.price}</span>
                            </div>
                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <p className="m-0" style={{color:'#2F747F', fontSize:'14px'}}> Negotiation : <span style={{color:'#555555'}}> {property.negotiation}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading properties...</p> // Show a loading message until data is fetched
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyCards;
