// import React from "react";

// const slides = [
//   "https://via.placeholder.com/800x400?text=Slide+1",
//   "https://via.placeholder.com/800x400?text=Slide+2",
//   "https://via.placeholder.com/800x400?text=Slide+3",
//   "https://via.placeholder.com/800x400?text=Slide+4",
// ];

// const 
// Carousel = () => {
//   return (
//     <div style={{ overflow: "hidden", width: "100%", whiteSpace: "nowrap" }}>
//       <div className="carousel-track">
//         {slides.concat(slides).map((slide, index) => (
//           <img
//             key={index}
//             src={slide}
//             alt={`Slide ${index + 1}`}
//             style={{
//               width: "300px",
//               height: "200px",
//               display: "inline-block",
//               marginRight: "10px",
//             }}
//           />
//         ))}
//       </div>
//       <style>
//         {`
//           .carousel-track {
//             display: inline-block;
//             animation: scroll 15s linear infinite;
//           }

//           @keyframes scroll {
//             0% { transform: translateX(0); }
//             100% { transform: translateX(-100%); }
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default 
// Carousel;


// import React, { useState, useEffect, useRef } from "react";
// import { FaRulerCombined, FaBed, FaUser, FaCalendarAlt } from "react-icons/fa";
// import { MdLocationOn } from "react-icons/md";

// const slides = [
//   "https://via.placeholder.com/800x400?text=Slide+1",
//   "https://via.placeholder.com/800x400?text=Slide+2",
//   "https://via.placeholder.com/800x400?text=Slide+3",
//   "https://via.placeholder.com/800x400?text=Slide+4",
// ];

// const 
// Carousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0); // Track current slide index
//   const intervalRef = useRef(null); // Ref to store interval
//   const autoScrollRef = useRef(true); // Flag to track if auto-scrolling is active

//   const product = {
//     id: 1,
//     image: slides[0], // Use carousel images or specific product images
//     name: "Beautiful House",
//     location: "New York",
//     price: "$500,000",
//     size: "2000 sqft",
//     bhk: "4 BHK",
//     owner: "John Doe",
//     time: "2 days ago",
//   };

//   // Auto-scroll functionality for continuous movement
//   useEffect(() => {
//     const startAutoScroll = () => {
//       intervalRef.current = setInterval(() => {
//         if (autoScrollRef.current) {
//           setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
//         }
//       }, 3000); // Adjust this value to make it scroll slower (e.g., 3000ms or more for slower)
//     };

//     startAutoScroll(); // Start scrolling on load

//     // Cleanup interval on unmount
//     return () => clearInterval(intervalRef.current);
//   }, []);

//   // Function to go to the next slide
//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length); // Move to the next slide
//     autoScrollRef.current = false; // Stop auto-scroll temporarily
//     setTimeout(() => {
//       autoScrollRef.current = true; // Restart auto-scroll after button click
//     }, 1000); // After 1 second delay, resume the scroll
//   };

//   // Function to go to the previous slide
//   const prevSlide = () => {
//     setCurrentIndex(
//       (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
//     ); // Move to the previous slide
//     autoScrollRef.current = false; // Stop auto-scroll temporarily
//     setTimeout(() => {
//       autoScrollRef.current = true; // Restart auto-scroll after button click
//     }, 1000); // After 1 second delay, resume the scroll
//   };

//   return (
//     <div style={{ overflow: "hidden", width: "100%" }}>
//       <div
//         className="carousel-track"
//         style={{
//           display: "flex",
//           flexWrap: "nowrap",
//           animation: "scroll 15s linear infinite", // Apply continuous scroll animation
//         }}
//       >
//         {slides.concat(slides).map((slide, index) => (
//           <div
//             className="col-lg-4 col-md-6 col-sm-12 mb-4"
//             key={index}
//             style={{ flex: "0 0 auto", marginRight: "10px" }}
//           >
//             <div className="card h-100">
//               <img
//                 src={slide}
//                 className="card-img-top"
//                 alt={`Slide ${index + 1}`}
//                 style={{
//                   objectFit: "cover",
//                   width: "100%",
//                   height: "250px",
//                   objectPosition: "center",
//                 }}
//               />
//               <div className="card-body">
//                 <p className="card-title">{product.name}</p>
//                 <p className="card-text text-muted">
//                   <MdLocationOn /> {product.location}
//                 </p>
//                 <p className="card-text">
//                   <span className="text-success">{product.price}</span>
//                 </p>

//                 <div className="container p-0">
//                   <div className="row">
//                     <div className="col-md-6 col-6">
//                       <p>
//                         <FaRulerCombined className="icon" /> {product.size}
//                       </p>
//                     </div>
//                     <div className="col-md-6 col-6">
//                       <p>
//                         <FaBed className="icon ms-3" /> {product.bhk}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-md-6 col-6">
//                       <p>
//                         <FaUser className="icon" /> {product.owner}
//                       </p>
//                     </div>
//                     <div className="col-md-6 col-6">
//                       <p>
//                         <FaCalendarAlt className="icon ms-3" /> {product.time}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//                 <button
//                   className="btn btn-primary"
//                   style={{
//                     width: "100%",
//                     background: "#001F3F",
//                     color: "#FFC631",
//                     border: "none",
//                     height: "50px",
//                   }}
//                 >
//                   VIEW DETAILS
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       <div style={{ textAlign: "center", marginTop: "10px" }}>
//         <button
//           onClick={prevSlide}
//           className="btn btn-secondary"
//           style={{
//             background: "#001F3F",
//             color: "#FFC631",
//             border: "none",
//             height: "50px",
//             width: "100px",
//           }}
//         >
//           Prev
//         </button>
//         <button
//           onClick={nextSlide}
//           className="btn btn-secondary"
//           style={{
//             background: "#001F3F",
//             color: "#FFC631",
//             border: "none",
//             height: "50px",
//             width: "100px",
//             marginLeft: "10px",
//           }}
//         >
//           Next
//         </button>
//       </div>

//       <style>
//         {`
//           .carousel-track {
//             display: flex;
//             flex-wrap: nowrap;
//             animation: scroll 15s linear infinite; /* Continuous scroll */
//           }

//           @keyframes scroll {
//             0% { transform: translateX(0); }
//             100% { transform: translateX(-100%); }
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default 
// Carousel;



import React, { useState, useEffect, useRef } from "react";
import { FaRulerCombined, FaBed, FaUser, FaCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

const slides = [
  "https://via.placeholder.com/800x400?text=Slide+1",
  "https://via.placeholder.com/800x400?text=Slide+2",
  "https://via.placeholder.com/800x400?text=Slide+3",
  "https://via.placeholder.com/800x400?text=Slide+4",
];

const 
Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track current slide index
  const carouselRef = useRef(null); // Ref for carousel track
  const [isAnimating, setIsAnimating] = useState(true); // Flag to control animation pause

  const product = {
    id: 1,
    image: slides[0], // Use carousel images or specific product images
    name: "Beautiful House",
    location: "New York",
    price: "$500,000",
    size: "2000 sqft",
    bhk: "4 BHK",
    owner: "John Doe",
    time: "2 days ago",
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setIsAnimating(false); // Stop auto-scrolling temporarily
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length); // Move to the next slide
    setTimeout(() => {
      setIsAnimating(true); // Resume auto-scrolling after 1 second delay
    }, 3000); // After 1 second delay, resume the scroll
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setIsAnimating(false); // Stop auto-scrolling temporarily
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    ); // Move to the previous slide
    setTimeout(() => {
      setIsAnimating(true); // Resume auto-scrolling after 1 second delay
    }, 3000); // After 1 second delay, resume the scroll
  };

  // Use keyframes for continuous scrolling without pausing
  useEffect(() => {
    const track = carouselRef.current;
    if (track) {
      track.style.animation = isAnimating
        ? "scroll 20s linear infinite" // Smooth animation
        : "none"; // Stop the animation temporarily when buttons are pressed
    }
  }, [isAnimating]);

  return (
    <>
    <h6 style={{color:'#105EEB '}}>FEATURE PROPERTY</h6>
    <div style={{ overflow: "hidden", width: "100%" , position: "relative", fontFamily: 'Inter, sans-serif'}}>
      <div
        className="carousel-track"
        ref={carouselRef}
        style={{
          display: "flex",
          flexWrap: "nowrap",
          transform: `translateX(-${currentIndex * 100}%)`, // Adjust the translateX for smooth scrolling
        }}
      >
        {slides.concat(slides).map((slide, index) => (
          <div
            className="col-lg-4 col-md-6 col-sm-12 mb-4"
            key={index}
            style={{ flex: "0 0 auto", marginRight: "10px" }}
          >
            <div className="card w-100">
              <img
                src={slide}
                className="card-img-top"
                alt={`Slide ${index + 1}`}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "250px",
                  objectPosition: "center",
                }}
              />
              <div className="card-body">
                <p className="card-title">{product.name}</p>
                <p className="card-text text-muted">
                  <MdLocationOn /> {product.location}
                </p>
                <p className="card-text">
                  <span className="text-success">{product.price}</span>
                </p>

                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <p>
                        <FaRulerCombined className="icon" /> {product.size}
                      </p>
                    </div>
                    <div className="col-md-6 col-6">
                      <p>
                        <FaBed className="icon ms-3" /> {product.bhk}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <p>
                        <FaUser className="icon" /> {product.owner}
                      </p>
                    </div>
                    <div className="col-md-6 col-6">
                      <p>
                        <FaCalendarAlt className="icon ms-3" /> {product.time}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  style={{
                    width: "100%",
                    background: "#001F3F",
                    color: "#FFC631",
                    border: "none",
                    height: "50px",
                  }}
                >
                  VIEW DETAILS
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div style={{ textAlign: "center", marginTop: "10px",   position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1, }}>
        <button
          onClick={prevSlide}
          className="btn btn-secondary"
          style={{
            background: "#001F3F",
            color: "#FFC631",
            border: "none",
            height: "50px",
            width: "100px",
          }}
        >
          Prev
        </button>
        <button
          onClick={nextSlide}
          className="btn btn-secondary"
          style={{
            background: "#001F3F",
            color: "#FFC631",
            border: "none",
            height: "50px",
            width: "100px",
            marginLeft: "10px",
          }}
        >
          Next
        </button>
      </div>

      <style>
        {`
          .carousel-track {
            display: flex;
            flex-wrap: nowrap;
            transition: transform 1s ease-in-out; /* Smooth transition */
          }

          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </div>
    </>
  );
};

export default 
Carousel;
