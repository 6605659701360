


import React, { useState, useEffect } from "react";
import { FaRulerCombined, FaBed, FaCalendarAlt, FaUser , FaUserAlt , FaRupeeSign  } from "react-icons/fa";
import { Button, Nav, Tab, Row, Col, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";  // Import useNavigate
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";

const MyProperty = ({property}) => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate hook
  const { phoneNumber: statePhoneNumber, countryCode: stateCountryCode } = location.state || {};
  const storedPhoneNumber = localStorage.getItem('phoneNumber');
  const storedCountryCode = localStorage.getItem('countryCode');

  const phoneNumber = statePhoneNumber || storedPhoneNumber;
  const countryCode = stateCountryCode || storedCountryCode;

  const [activeKey, setActiveKey] = useState("property");
  const [propertyUsers, setPropertyUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);

  
  useEffect(() => {
    if (activeKey === "property" && phoneNumber) {
      fetchPropertyData(phoneNumber);
    }
    if (activeKey === "removed" && phoneNumber) {
      fetchDeletedProperties(phoneNumber);
    }
  }, [activeKey, phoneNumber]);

  const fetchPropertyData = async (phoneNumber) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-status`, {
        params: { phoneNumber },
      });

      if (response.status === 200) {
        setPropertyUsers(response.data.users);
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
      toast.error("Error fetching property data.");
    }
  };

  const fetchDeletedProperties = async (phoneNumber) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/fetch-delete-status`, {
        params: { phoneNumber },
      });

      if (response.status === 200) {
        setRemovedUsers(response.data.users);
      }
    } catch (error) {
      console.error("Error fetching deleted properties:", error);
      toast.error("Error fetching deleted properties.");
    }
  };

  // Handle delete property
  const handleDelete = async (ppcId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete-property`, {
        ppcId,
        phoneNumber,
      });

      if (response.status === 200) {
        toast.success("Property deleted successfully!");
        // Move property from propertyUsers to removedUsers
        setPropertyUsers((prev) => prev.filter((user) => user.ppcId !== ppcId));
        setRemovedUsers((prev) => [...prev, { ...response.data.user }]); 
      }
    } catch (error) {
      toast.error("Error deleting property.");
      console.error("Delete Error:", error);
    }
  };

  // Handle undo deletion
  const handleUndo = async (ppcId) => {
    try {
      const response = await axios.post('http://localhost:5000/PPC/undo-delete', {
        ppcId,
        phoneNumber,
      });

      if (response.status === 200) {
        toast.success("Property status reverted successfully!");
        // Move property from removedUsers back to propertyUsers
        setRemovedUsers((prev) => prev.filter((user) => user.ppcId !== ppcId));
        setPropertyUsers((prev) => [...prev, { ...response.data.user }]); 
      }
    } catch (error) {
      toast.error("Error undoing property status.");
      console.error("Undo Error:", error);
    }
  };

  // Handle Add Property click (API call and navigate to Add-Form)
  const handleAddProperty = async () => {
    if (!phoneNumber || !countryCode) {
      toast.error("Missing phone number or country code.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/store-data`, {
        phoneNumber: `${phoneNumber}`,
      });

      if (response.status === 201) {
        // Navigate to Add-Form page with ppcId and phone number as state
        setTimeout(() => {
          navigate("/add-form", {
            state: {
              ppcId: response.data.ppcId,
              phoneNumber: `${phoneNumber}`,
            },
          });
        }, 100);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Error adding user.");
      } else {
        toast.error("Error adding user. Please try again.");
      }
      console.error("Frontend Error:", error);
    }
  };



  const handleEdit = (ppcId, phoneNumber) => {
    if (ppcId) {
      navigate('/edit-form', { state: { ppcId, phoneNumber } });
    } else {
      toast.error("Property not found.");
    }
  };
  
  

  return (
    <Container fluid className="p-3 my-3">
      <Helmet>
        <title>Pondy Property | Properties</title>
      </Helmet>

      {/* Tab Navigation */}
      <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
        <Row className="g-3">
          <Col lg={12} className="d-flex flex-column align-items-center">
            {/* Navbar Tabs */}
            <Nav variant="tabs" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="property">Property</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="removed">Removed</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="expired">Expired</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="add-prop" onClick={handleAddProperty}>Add Property</Nav.Link>
              </Nav.Item>
            </Nav>

            {/* Tab Content */}
            <Tab.Content>
              {/* Property Tab */}
              <Tab.Pane eventKey="property">
                {propertyUsers && propertyUsers.length > 0 ? (
                  propertyUsers.map((user, index) => (
          <div key={user._id}
          className="card mb-3 shadow p-1"
          style={{ width: '100%', minWidth: '400px', height: 'auto', background:'#F9F9F9' }} // Make width responsive
          >
          <div className="row g-0">
            {/* Image Section */}
            <div className="col-md-4 d-flex flex-column justify-content-between align-items-center">
              {/* Top: PUC Code */}
              <div
                className="text-white py-1 px-2 text-center"
                style={{ width: '100%' , background:"#2F747F"}}
              >
                PUC- {user.ppcId}
              </div>
              {/* Middle: Image */}
              <div className="img-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                <img
                 src={
                           user.photos && user.photos.length > 0
                            ? `http://localhost:5000/${user.photos[0]}`
                             : "https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"
                        }
                         alt={`Property ${index + 1}`}
                  className="img-fluid"
                  style={{
                    width: '100%',
                    height: '100px',
                    objectFit: 'cover', // Try 'contain' if you want the full image visible without clipping
                  }}
                />
              </div>
              {/* Bottom: Incomplete */}
              <div
                className=" py-1 px-1 text-center"
                style={{ width: '100%', background:'#FF4500', color:'#fff' }}
              >
                {user.status}
              </div>
            </div>
          
            {/* Content Section */}
            <div className="col-md-8">
            <p>{user.propertyMode}</p>
            <p>{user.city}</p>
              <div className="card-body p-2 d-flex flex-column justify-content-center">
                {/* Align Icons and Text */}
                <div className="row">
                  <div className="col-6 d-flex align-items-center mt-1 mb-1">
                    <FaRulerCombined className="me-2"color="#2F747F" /> <span style={{fontSize:'14px', color:'#555555'}}>{user.areaUnit}</span>
                  </div>
                  <div className="col-6 d-flex align-items-center mt-1 mb-1">
                    <FaBed className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.bedrooms}</span>
                  </div>
                  <div className="col-6 d-flex align-items-center mt-1 mb-1">
                    <FaUserAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.ownership}</span>
                  </div>
                  <div className="col-6 d-flex align-items-center mt-1 mb-1">
                    <FaCalendarAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.bestTimeToCall}</span>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-center mt-1 mb-1">
                    <FaRupeeSign className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.price}</span>
                  </div>
                <p style={{fontSize:"12px"}}> Edit And Submit Ad to complete </p>
          
                {/* Buttons */}
                <div className="d-flex justify-content-around mt-2">
                  <button className="btn btn-sm" style={{background:'#FF4500',color:'#fff', width:'40%'}}
                  onClick={() => handleDelete(user.ppcId)} 
                  >Remove</button>
                  {/* <button className="btn btn-sm"  style={{background:'#2F747F',color:'#fff' , width:'40%'}}
                              onClick={() => handleEdit(user.ppcId)}
          >Edit</button> */}
          <button
  className="btn btn-sm"
  style={{ background: '#2F747F', color: '#fff', width: '40%' }}
  onClick={() => handleEdit(user.ppcId, user.phoneNumber)} // Passing phone number
>
  Edit
</button>

                </div>
              </div>
            </div>
          </div>
          </div>
                  ))
                ) : (
                  <div className="text-center">
                    <p>No Property Data Found</p>
                  </div>
                )}
              </Tab.Pane>

              {/* Removed Tab */}
              <Tab.Pane eventKey="removed">
                {removedUsers && removedUsers.length > 0 ? (
                  removedUsers.map((user) => (
                    <div
                                      key={user._id}
                                      className="card mb-3 shadow p-1"
                                      style={{ width: '100%', minWidth: '400px', height: 'auto', background:'#F9F9F9' }} // Make width responsive
                                      >
                                      <div className="row g-0">
                                        {/* Image Section */}
                                        <div className="col-md-4 d-flex flex-column justify-content-between align-items-center">
                                          {/* Top: PUC Code */}
                                          <div
                                            className="text-white py-1 px-2 text-center"
                                            style={{ width: '100%' , background:"#2F747F"}}
                                          >
                                             PUC- {user.ppcId}
                                          </div>
                                          {/* Middle: Image */}
                                          <div className="img-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                                            <img
                                                       src={
                                                         user.photos && user.photos.length > 0
                                                           ? `http://localhost:5000/${user.photos[0]}`
                                                           : "https://d17r9yv50dox9q.cloudfront.net/car_gallery/default.jpg"
                                                       }
                                                       alt="Property"
                                              className="img-fluid"
                                              style={{
                                                width: '100%',
                                                height: '100px',
                                                objectFit: 'cover', // Try 'contain' if you want the full image visible without clipping
                                              }}
                                            />
                                          </div>
                                          {/* Bottom: Incomplete */}
                                          <div
                                            className=" py-1 px-2 text-center"
                                            style={{ width: '100%', background:'#FF4500', color:'#fff' }}
                                          >
                                            Incomplete
                                          </div>
                                        </div>
                                      
                                        {/* Content Section */}
                                        <div className="col-md-8">
                                        <p>{user.propertyMode}</p>
                                        <p>{user.city}</p>
                                          <div className="card-body p-2 d-flex flex-column justify-content-center">
                                            {/* Align Icons and Text */}
                                            <div className="row">
                                              <div className="col-6 d-flex align-items-center mt-1 mb-1">
                                                <FaRulerCombined className="me-2"color="#2F747F" /> <span style={{fontSize:'14px', color:'#555555'}}>{user.areaUnit}</span>
                                              </div>
                                              <div className="col-6 d-flex align-items-center mt-1 mb-1">
                                                <FaBed className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.bedrooms}</span>
                                              </div>
                                              <div className="col-6 d-flex align-items-center mt-1 mb-1">
                                                <FaUserAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.ownership}</span>
                                              </div>
                                              <div className="col-6 d-flex align-items-center mt-1 mb-1">
                                                <FaCalendarAlt className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.bestTimeToCall}</span>
                                              </div>
                                            </div>
                                            <div className="col-6 d-flex align-items-center mt-1 mb-1">
                              <FaRupeeSign className="me-2" color="#2F747F"/> <span style={{fontSize:'14px', color:'#555555'}}>{user.price}</span>
                            </div>                  
                                            {/* Buttons */}
                                            <div className="d-flex justify-content-between mt-2">
                                              <button className="btn btn-sm"  style={{background:'#2F747F',color:'#fff', width:'50%'}}
                                               onClick={() => handleUndo(user.ppcId)}>Undo</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      </div>
                  ))
                ) : (
                  <div className="text-center">
                    <p>No Removed Property Found</p>
                  </div>
                )}
              </Tab.Pane>

              {/* Expired Tab */}
              <Tab.Pane eventKey="expired">
                <h5>No expired properties yet.</h5>
              </Tab.Pane>

              {/* Add Property Tab */}
              <Tab.Pane eventKey="add-prop">
                <h5>Add Property Form (Coming Soon)</h5>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default MyProperty;
